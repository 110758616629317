import { ApiModuleBase } from '../ApiModuleBase.js'

export class ExportStates extends ApiModuleBase {
    static get namespace () {
        return 'exportStates'
    }

    static get urlNamespace () {
        return 'export-states'
    }
}
