import { ApiModuleBase } from '../ApiModuleBase.js'

export class AmlSessionRealOwners extends ApiModuleBase {
    static get namespace () {
        return 'amlSessionRealOwners'
    }

    static get urlNamespace () {
        return 'aml-session/real-owners'
    }
}
