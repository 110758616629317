import { ApiModuleBase } from '../ApiModuleBase.js'

export class Users extends ApiModuleBase {
    static get namespace () {
        return 'users'
    }

    static get urlNamespace () {
        return 'users'
    }

    async getAllUsers () {
        const users = (await this.read()).data
        const ldapUsers = (await this.read('ldap')).data
        return ldapUsers.map(ldapUser => {
            const user = users.filter(item => item.sAMAccountName === ldapUser.sAMAccountName)
            return {
                ...ldapUser,
                id: (user.length > 0) ? user[0]._id : null
            }
        })
    }
}
