<template>
    <div class="position-relative">
        <div :class="{ '__loading-overlay': true,  '__visible': loading}">
            <component-loader v-if="loading" />
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'LoaderOverlay',
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss">
    .__loading-overlay {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 0;
        height: 0;
        background-color: rgba(255,255,255, .5);
        z-index: -1;
    }

    .__loading-overlay.__visible {
        width: 100%;
        height: 100%;
        z-index: 1000;
        opacity: 1;
        transition: opacity .3s ease-in 0;
    }

    .__loading-overlay .__loader-wrapper {
        top: 50%;
        transform: translateY(-50%);
    }
</style>
