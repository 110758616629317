import ComponentError from '../Components/ComponentError.vue'
import ComponentLoader from '../Components/ComponentLoader.vue'
import LoaderOverlay from '../Components/LoaderOverlay.vue'
import Loader from '../Components/Loader.vue'
import DetailItem from '../Components/DetailItem.vue'

export const GlobalComponentsPlugin = {
    install (Vue) {
        Vue.component('component-loader', ComponentLoader)
        Vue.component('component-error', ComponentError)
        Vue.component('loader-overlay', LoaderOverlay)
        Vue.component('loader', Loader)
        Vue.component('detail-item', DetailItem)
    }
}
