export default {
    state: () => ({
        externalAcquirer: null
    }),
    getters: {
        externalAcquirer (state) {
            return state.externalAcquirer
        }
    },
    mutations: {
        setExternalAcquirer (state, externalAcquirer) {
            state.externalAcquirer = externalAcquirer
        }
    },
    actions: {

    }
}
