import { ApiModuleBase } from '../ApiModuleBase.js'
import download from 'downloadjs'

export class Sessions extends ApiModuleBase {
    static get namespace () {
        return 'sessions'
    }

    static get urlNamespace () {
        return 'sessions'
    }

    async exportToIvan (sessionId) {
        return await this.doGetRequest(`${this.ns}/${sessionId}/export`)
    }

    async changeState (sessionId, state) {
        return this.doPatchRequest(`${this.ns}/${sessionId}/state`, state)
    }

    async getAllFiles (sessionId) {
        return this.doGetRequest(`${this.ns}/${sessionId}/uploads`)
    }

    async downloadFile (sessionId, uploadId) {
        const response = await this.client.get(
            `/${this.ns}/${sessionId}/uploads/${uploadId}`,
            {
                responseType: 'blob'
            }
        )
        if (response.status === 200) {
            download(response.data, response.headers['content-disposition'].replace('attachment; filename="', '').replace('"', ''))
        }
    }
}
