import { customAlphabet } from 'nanoid'
const defaultCharacterSet = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'

export function generateString (length = 10, characterSet) {
    return customAlphabet(characterSet || defaultCharacterSet, length)()
}

export default {
    install (Vue) {
        Vue.prototype.$randomString = generateString
    }
}
