import swal from 'sweetalert'

class SwalDialog {
    constructor (VuePrototype) {
        this.VuePrototype = VuePrototype
        this.defaults = {
            title: '',
            text: '',
            icon: 'warning',
            buttonsStyling: false,
            buttons: {
                ok: {
                    text: 'OK',
                    value: 'ok',
                    visible: true,
                    className: 'btn btn-sm mx-2 btn-primary',
                    closeModal: true
                },
                cancel: {
                    text: 'Storno',
                    value: 'cancel',
                    visible: true,
                    className: 'btn btn-sm mx-2 btn-secondary',
                    closeModal: true
                }
            }
        }
    }

    showDialog (type, title, text, okText = null, cancelText = null, okClassNames = null, cancelClassNames = null) {
        const settings = {
            ...this.defaults,
            title,
            text,
            icon: type
        }
        if (okText) {
            settings.buttons.ok.text = okText
        }
        if (okClassNames) {
            settings.buttons.ok.className = okClassNames
        }
        if (cancelText) {
            settings.buttons.cancel.text = cancelText
        }
        if (cancelClassNames) {
            settings.buttons.cancel.className = cancelClassNames
        }
        return swal(settings)
    }

    error (title, text, okText = null, cancelText = null, okClassNames = null, cancelClassNames = null) {
        return this.showDialog('error', title, text, okText, cancelText, okClassNames, cancelClassNames)
    }

    warning (title, text, okText = null, cancelText = null, okClassNames = null, cancelClassNames = null) {
        return this.showDialog('warning', title, text, okText, cancelText, okClassNames, cancelClassNames)
    }

    info (title, text, okText = null, cancelText = null, okClassNames = null, cancelClassNames = null) {
        return this.showDialog('info', title, text, okText, cancelText, okClassNames, cancelClassNames)
    }

    success (title, text, okText = null, cancelText = null, okClassNames = null, cancelClassNames = null) {
        return this.showDialog('success', title, text, okText, cancelText, okClassNames, cancelClassNames)
    }
}

export const SwalPlugin = {
    install (Vue) {
        Vue.prototype.$swal = new SwalDialog(Vue)
    }
}
