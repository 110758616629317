import Router from 'vue-router'
import ComponentLoader from '../../Components/ComponentLoader.vue'
import ComponentError from '../../Components/ComponentError.vue'
import { HeadRequestGuard } from './HeadRequestGuard.js'
import adminRouter from './adminRouter'
const loaderSettings = {
    loading: ComponentLoader,
    error: ComponentError,
    delay: 10,
    timeout: 30 * 1000
}

const createRouter = (adminGuard, appGuard) => {
    return new Router({
        mode: 'history',
        base: '/app',
        scrollBehavior (to, from, savedPosition) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({ x: 0, y: 0 })
                }, 150)
            })
        },
        routes: [
            {
                path: '',
                component: () => import(/* webpackChunkName: "index" */ '../../Views/App/Index.vue'),
                ...loaderSettings,
                children: [
                    {
                        path: '',
                        name: 'Index',
                        component: () => import(/* webpackChunkName: "typeSelection" */ '../../Views/App/Views/TypeSelection/TypeSelection.vue'),
                        ...loaderSettings
                    },
                    {
                        path: 'odeslano',
                        name: 'SessionDataSent',
                        component: () => import(/* webpackChunkName: "sessionDataSent" */ '../../Views/App/Views/SessionDataSent/SessionDataSent.vue'),
                        ...loaderSettings
                    },
                    {
                        path: 'osobni-udaje',
                        name: 'BasicData',
                        component: () => import(/* webpackChunkName: "basicData" */ '../../Views/App/Views/BasicData/BasicData.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'basicdata' }, { name: 'Index' })
                    },
                    {
                        path: 'dokumenty',
                        name: 'PersonalId',
                        component: () => import(/* webpackChunkName: "personalId" */ '../../Views/App/Views/PersonalId/PersonalId.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'personalid' }, { name: 'BasicData' })
                    },
                    {
                        path: 'bankovni-ucty',
                        name: 'BankAccounts',
                        component: () => import(/* webpackChunkName: "bankAccounts" */ '../../Views/App/Views/BankAccounts/BankAccounts.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'bankaccounts' }, { name: 'BasicData' })
                    },
                    {
                        path: 'aml-informace',
                        name: 'LegalInfo',
                        component: () => import(/* webpackChunkName: "legalInfo" */ '../../Views/App/Views/LegalInfo/LegalInfo.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'legalinfo' }, { name: 'BankAccounts' })
                    },
                    {
                        path: 'jednatele',
                        name: 'CompanyOwners',
                        component: () => import(/* webpackChunkName: "companyOwners" */ '../../Views/App/Views/CompanyOwners/CompanyOwners.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'companyowners' }, { name: 'BasicData' })
                    },
                    {
                        path: 'opravnene-osoby',
                        name: 'CompanyBrokers',
                        component: () => import(/* webpackChunkName: "companyBrokers" */ '../../Views/App/Views/CompanyBrokers/CompanyBrokers.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'companybrokers' }, { name: 'CompanyOwners' })
                    },
                    {
                        path: 'skutecni-majitele',
                        name: 'RealOwners',
                        component: () => import(/* webpackChunkName: "realOwners" */ '../../Views/App/Views/RealOwners/RealOwners.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'realowners' }, { name: 'CompanyBrokers' })
                    },
                    {
                        path: 'podpis',
                        name: 'Signature',
                        component: () => import(/* webpackChunkName: "signature" */ '../../Views/App/Views/Signature/Signature.vue'),
                        ...loaderSettings,
                        beforeEnter: appGuard.beforeEnter({ step: 'signature' }, { name: 'BasicData' })
                    }
                ]
            },
            {
                path: '/aml-dotaznik',
                component: () => import(/* webpackChunkName: "amlSurvey" */ '../../Views/App/Views/AML/Index.vue'),
                ...loaderSettings,
                children: [
                    {
                        path: '',
                        name: 'AMLLegalInfo',
                        component: () => import(/* webpackChunkName: "amlLegalInfo" */ '../../Views/App/Views/AML/Views/LegalInfo/LegalInfo.vue'),
                        ...loaderSettings
                    },
                    {
                        path: 'majitele',
                        name: 'AMLRealOwners',
                        component: () => import(/* webpackChunkName: "amlRealOwners" */ '../../Views/App/Views/AML/Views/RealOwners/RealOwners.vue'),
                        ...loaderSettings
                    },
                    {
                        path: 'podpis',
                        name: 'AMLSignature',
                        component: () => import(/* webpackChunkName: "amlSignature" */ '../../Views/App/Views/AML/Views/Signature/Signature.vue'),
                        ...loaderSettings
                    }
                ]
            },
            {
                path: '/kontrola-dat',
                component: () => import(/* webpackChunkName: "amlSurvey" */ '../../Views/App/Views/DataCheckup/Index.vue'),
                ...loaderSettings,
                children: [
                    {
                        path: '',
                        name: 'DataCheckup',
                        component: () => import(/* webpackChunkName: "checkUserData" */ '../../Views/App/Views/DataCheckup/Views/DataCheckup.vue'),
                        ...loaderSettings
                    },
                    {
                        path: 'odeslano',
                        name: 'DataCheckupSent',
                        component: () => import(/* webpackChunkName: "sessionDataSent" */ '../../Views/App/Views/DataCheckup/Views/DataCheckupSent.vue'),
                        ...loaderSettings
                    }
                ]
            },
            {
                path: '/error',
                name: 'ErrorPage',
                component: () => import(/* webpackChunkName: "errorPage" */ '../../Views/App/Views/Error/ErrorPage.vue'),
                ...loaderSettings
            },
            {
                path: '/prihlaseni',
                name: 'Login',
                component: () => import(/* webpackChunkName: "login" */ '../../Views/App/Views/Login/Login.vue'),
                ...loaderSettings
            },
            ...adminRouter(adminGuard, loaderSettings)
        ]
    })
}

export class RouterFactory {
    constructor (appSettings) {
        this.appSettings = appSettings
        this.adminGuard = new HeadRequestGuard(this.appSettings.ADMIN_APP_GUARD_URL, { name: 'Login' })
        this.appGuard = new HeadRequestGuard(this.appSettings.APP_GUARD_URL, { name: 'Index' })
        this._router = createRouter(this.adminGuard, this.appGuard)
    }

    get router () {
        return this._router
    }
}
