export function birthNumberFormatter (value) {
    const tmp = value.replace(/[\s\D]/g, '')
    return `${tmp.slice(0, 6)}/${tmp.slice(6, 10)}`
}

export function phoneFormatter (value) {
    /* eslint-disable-next-line no-useless-escape */
    const tmp = value.replace(/[^\+\d]/g, '')
    return tmp
}

export function numericFormatter (value) {
    return value.replace(/\D/g, '')
}

export function formatSwift (value) {
    const tmp = value.match(/[a-z0-9]/ig)
    if (tmp === null) {
        return value
    }
    return tmp.join('').toUpperCase()
}

export function formatBankAccountPrefix (value) {
    let prefix = numericFormatter(('' + value))
    const prefixLength = prefix.length
    for (let i = 0; i < (6 - prefixLength); i++) {
        prefix = '0' + prefix
    }
    return prefix
}

export function formatBankAccount (value) {
    let account = numericFormatter(('' + value))
    const accountLength = account.length
    for (let i = 0; i < (10 - accountLength); i++) {
        account = '0' + account
    }
    return account
}

export default {
    install (Vue) {
        Vue.prototype.$stringFormat = {
            bankAccount: formatBankAccount,
            bankAccountPrefix: formatBankAccountPrefix,
            swift: formatSwift,
            numeric: numericFormatter,
            phoneNumber: phoneFormatter,
            birthNumber: birthNumberFormatter
        }
    }
}
