<template>
    <div id="app" class="main-flex">
        <header class="main-header shadow-sm">
            <top-bar />
            <app-navbar>
                <app-navigation />
            </app-navbar>
        </header>

        <div :class="{
            'main-content': true,
            'bg-gradient-cyrrus-gray-dark': ($route.name !== 'Login'),
            'bg-gradient-cyrrus-yellow': ($route.name === 'Login')
        }">
            <transition
                enter-active-class="animated fadeIn faster"
                leave-active-class="animated fadeOut faster"
                mode="out-in"
            >
                <router-view></router-view>
            </transition>
        </div>

        <app-footer/>
    </div>
</template>

<script>
import AppFooter from './Components/Layout/Footer.vue'
import TopBar from './Components/Layout/TopBar.vue'
import AppNavbar from './Components/Layout/AppNavbar.vue'
import AppNavigation from './Components/AppNavigation.vue'

export default {
    name: 'App',
    components: {
        AppFooter,
        TopBar,
        AppNavbar,
        AppNavigation
    },
    beforeMount () {
        const adminRoutePrefixes = [
            '/app/admin'
        ]
        for (const prefix of adminRoutePrefixes) {
            if (`${window.location.pathname}`.startsWith(prefix)) {
                this.$user.reload()
                break
            }
        }
    },
    computed: {
        user () {
            return this.$user
        }
    }
}
</script>

<style lang="scss">
@import './scss/app.scss';

.form-sm .custom-select {
    height: 2.5rem;
    padding: .5rem;
    border-radius: 0;
}
</style>
