class ServerValidator {
    #callbacks = new Map()
    #apiClient

    constructor (apiClient) {
        this.#apiClient = apiClient
    }

    addHandler (name, callbackFn) {
        if (typeof callbackFn !== 'function') {
            throw new Error('Invalid callback')
        }
        const existing = this.#callbacks.get(name) || []
        this.#callbacks.set(name, [...existing, callbackFn])
    }

    removeHandler (name, callbackFn) {
        if (typeof callbackFn !== 'function') {
            throw new Error('Invalid callback')
        }
        const existing = this.#callbacks.get(name) || []
        this.#callbacks.set(name, [...existing.filter(item => item !== callbackFn)])
    }

    async validate (name, data) {
        const callbacks = this.#callbacks.get(name) || []
        if (callbacks.length > 0) {
            try {
                await this.#apiClient.clientSession.validateData(data, name)
                for (const callbackFn of callbacks) {
                    try { callbackFn(true) } catch (error) { console.error(error) }
                }
            } catch (error) {
                for (const callbackFn of callbacks) {
                    try { callbackFn(false, error.response.data.documentId) } catch (error) { console.error(error) }
                }
                throw error
            }
        }
    }
}

export const ServerValidatorPlugin = (apiClient) => {
    return {
        install (Vue) {
            Vue.prototype.$serverValidator = new ServerValidator(apiClient)
        }
    }
}
