import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import AppNavigation from './Components/AppNavigation.vue'
import { RouterFactory } from './Services/Router/RouterFactory.js'
import Vuelidate from 'vuelidate'
import { BootstrapComponentsPlugin } from './Plugins/BootstrapComponentsPlugin.js'
import { IconsLibraryPlugin } from './Plugins/IconsLibraryPlugin.js'
import { ApiPlugin } from './Plugins/ApiPlugin.js'
import { AxiosPlugin } from './Plugins/AxiosPlugin.js'
import { UserPlugin } from './Plugins/UserPlugin.js'
import Settings from './Settings.js'
import { SettingsPlugin } from './Plugins/SettingsPlugin.js'
import store from './Services/Vuex/index.js'
import { GlobalComponentsPlugin } from './Plugins/GlobalComponentsPlugin.js'
import { NotifierPlugin } from './Plugins/NotifyPlugin.js'
import { DataTablePlugin } from '@tvaliasek/vue-datatable'
import { FormInputsPlugin } from '@tvaliasek/vue-form-inputs'
import RandomStringPlugin from './Plugins/RandomStringPlugin.js'
import StringFormattersPlugin from './Plugins/StringFormattersPlugin.js'
import '@tvaliasek/vue-datatable/dist/vueDataTable.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import i18n from './i18n.js'
import { SwalPlugin } from './Plugins/SweetAlertPlugin.js'
import Vuebar from 'vuebar'
import { ServerValidatorPlugin } from './Plugins/ServerValidatorPlugin'

Vue.config.productionTip = false

const settings = new Settings()
const router = (new RouterFactory(settings)).router

export class Bootstrap {
    initialize () {
        const apiPlugin = ApiPlugin(`${settings.API_BASE}`, 'api')
        Vue.use(GlobalComponentsPlugin)
        Vue.use(FormInputsPlugin)
        Vue.use(DataTablePlugin)
        Vue.use(SettingsPlugin(settings))
        Vue.use(VueRouter)
        Vue.use(BootstrapComponentsPlugin)
        Vue.use(IconsLibraryPlugin)
        Vue.use(apiPlugin)
        Vue.use(AxiosPlugin)
        Vue.use(Vuelidate)
        Vue.use(UserPlugin)
        Vue.use(NotifierPlugin)
        Vue.use(RandomStringPlugin)
        Vue.use(StringFormattersPlugin)
        Vue.use(UserPlugin)
        Vue.use(SwalPlugin)
        Vue.use(Vuebar)
        Vue.use(ServerValidatorPlugin(apiPlugin.getApiClient()))
    }

    render (
        appElement,
        navElement
    ) {
        if (!store.state.lang) {
            switch (navigator.language[0].substr(0, 2)) {
                case 'en':
                    store.commit('setLang', 'en')
                    break
                default:
                    store.commit('setLang', 'cz')
            }
        }
        i18n.locale = `${store.state.lang}`

        const app = new Vue(
            {
                router,
                store,
                i18n,
                render: function (h) {
                    return h(App)
                }
            }
        )

        const navigation = new Vue(
            {
                router,
                store,
                i18n,
                render: function (h) {
                    return h(AppNavigation)
                }
            }
        )

        app.$mount(appElement)
        navigation.$mount(navElement)
    }
}
