import { ApiModuleBase } from '../ApiModuleBase.js'

export class AmlSessionUploads extends ApiModuleBase {
    static get namespace () {
        return 'amlSessionUploads'
    }

    static get urlNamespace () {
        return 'aml-session/uploads'
    }

    get uploaderUrl () {
        return `${this.client.defaults.baseURL}/${this.ns}`
    }
}
