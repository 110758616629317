import { ApiModuleBase } from '../ApiModuleBase.js'

export class Locale extends ApiModuleBase {
    static get namespace () {
        return 'locale'
    }

    static get urlNamespace () {
        return 'locale'
    }

    async update (locale) {
        return await this.doPatchRequest(`/${this.ns}`, { locale })
    }
}
