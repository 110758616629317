import axios from 'axios'
const client = axios.create({
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest'
    }
})

client.interceptors.response.use(
    function (response) {
        return response
    }, function (error) {
        if (error.response &&
            error.response.data &&
            error.response.status === 403 &&
            error.response.data.location
        ) {
            window.location = error.response.data.location
        }
        return Promise.reject(error)
    }
)

export const axiosClient = client
export const AxiosPlugin = {
    install (Vue) {
        Vue.prototype.$axios = client
    }
}
