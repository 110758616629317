const getDefaultState = () => (
    {
        personalId: [],
        companyPerson: [],
        legalInfoUploads: []
    }
)
export default {
    state: getDefaultState,
    getters: {
    },
    mutations: {
        setLegalInfoUploads (state, uploads) {
            state.legalInfoUploads = [...uploads]
        },
        setPersonalIdUploads (state, uploadIds) {
            state.personalId = [...uploadIds]
        },
        setCompanyPersonUploads (state, uploadIds) {
            state.companyPerson = [...uploadIds]
        },
        resetStore (state) {
            Object.assign(state, getDefaultState())
        }
    },
    actions: {

    }
}
