import { ApiModuleBase } from '../ApiModuleBase.js'

export class AmlSession extends ApiModuleBase {
    static get namespace () {
        return 'amlSession'
    }

    static get urlNamespace () {
        return 'aml-session'
    }

    async update (data) {
        return await this.doPatchRequest(`/${this.ns}`, data)
    }

    async closeSession () {
        return await this.doPatchRequest(`/${this.ns}/state`, { state: 'process' })
    }
}
