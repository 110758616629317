import { ApiModuleBase } from '../ApiModuleBase.js'

export class ExternalAcquirers extends ApiModuleBase {
    static get namespace () {
        return 'externalAcquirers'
    }

    static get urlNamespace () {
        return 'external-acquirers'
    }

    async checkIzCode (izCode) {
        return this.doGetRequest(`${this.ns}/iz-code/${izCode}`)
    }
}
