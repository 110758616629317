import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import enums from './Modules/enums.js'
import uploads from './Modules/uploads.js'
import dataHashes from './Modules/dataHashes.js'
import externalAcquirer from './Modules/externalAcquirer'
import formData from './Modules/formData'

Vue.use(Vuex)

export default new Vuex.Store({
    state: () => ({
        lang: 'cz'
    }),
    mutations: {
        setLang (state, lang) {
            state.lang = lang
        }
    },
    getters: {},
    actions: {},
    modules: {
        enums,
        uploads,
        dataHashes,
        externalAcquirer,
        formData
    },
    plugins: [
        createPersistedState({
            key: 'cfxContractsApp',
            storage: window.sessionStorage,
            paths: [
                'lang',
                'uploads'
            ]
        })
    ]
})
