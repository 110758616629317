export class ApiModuleBase {
    constructor (client, urlNamespace = null, urlPrefix = '', submodules = []) {
        this.client = client
        this.urlNamespace = urlNamespace
        this.urlPrefix = urlPrefix
        this.submodules = submodules

        for (const submodule of this.submodules) {
            this[submodule.namespace] = (id) => {
                return submodule.create(this.client, `${this.ns}/${id}`)
            }
        }
    }

    static get namespace () {
        return 'api'
    }

    static get urlNamespace () {
        return 'api'
    }

    get ns () {
        let url = ''
        if (this.urlNamespace) {
            url = this.urlNamespace
        } else {
            url = this.namespace
        }
        url = `${this.urlPrefix}/${url}`.replace(/\/{2,}/g, '/').split('')
        if (url[0] === '/') {
            url[0] = ''
        }
        if (url[url.length - 1] === '/') {
            url[url.length - 1] = ''
        }
        return url.join('')
    }

    doGetRequest (url, params = {}, customConfig = {}) {
        return this.client.get(url, { params: params, ...customConfig })
    }

    doHeadRequest (url, params = {}, customConfig = {}) {
        return this.client.head(url, { params: params, ...customConfig })
    }

    doPostRequest (url, data = {}, params = {}, customConfig = {}) {
        if (data === undefined || data === null) {
            data = {}
        }
        return this.client.post(url, data, { params: params, ...customConfig })
    }

    doPutRequest (url, data = {}, params = {}, customConfig = {}) {
        if (data === undefined || data === null) {
            data = {}
        }
        return this.client.put(url, data, { params: params, ...customConfig })
    }

    doPatchRequest (url, data = {}, params = {}, customConfig = {}) {
        if (data === undefined || data === null) {
            data = {}
        }
        return this.client.patch(url, data, { params: params, ...customConfig })
    }

    doDeleteRequest (url, params = {}, customConfig = {}) {
        return this.client.delete(url, { params: params, ...customConfig })
    }

    getAll (params) {
        return this.doGetRequest(`/${this.ns}`, params)
    }

    getOne (id) {
        return this.doGetRequest(`/${this.ns}/${id}`)
    }

    /**
     * Basic CRUD operations
     */

    create (dataset) {
        return this.doPostRequest(`/${this.ns}`, dataset)
    }

    read (id = null, params = {}) {
        if (id === null || id === undefined) {
            return this.getAll(params)
        }
        return this.getOne(id)
    }

    update (id, dataset) {
        return this.doPatchRequest(`/${this.ns}/${id}`, dataset)
    }

    delete (id) {
        return this.doDeleteRequest(`/${this.ns}/${id}`)
    }

    replace (id, dataset) {
        return this.doPutRequest(`/${this.ns}/${id}`, dataset)
    }
}
