export default {
    state: () => ({
        basicData: null,
        personalId: null,
        bankAccounts: [],
        companyBrokers: [],
        companyOwners: [],
        legalInfo: null,
        realOwners: [],
        amlLegalInfo: null,
        shouldTouch: false
    }),
    getters: {
    },
    mutations: {
        setFormData (state, data) {
            if (data && state[data.name] !== undefined) {
                state[data.name] = data.content
            }
        }
    },
    actions: {

    }
}
