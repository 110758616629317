import { ApiModuleBase } from '../ApiModuleBase.js'

export class ClientSession extends ApiModuleBase {
    static get namespace () {
        return 'clientSession'
    }

    static get urlNamespace () {
        return 'client-session'
    }

    async update (data) {
        return await this.doPatchRequest(`/${this.ns}`, data)
    }

    async closeSession () {
        return await this.doPatchRequest(`/${this.ns}/state`, { state: 'process' })
    }

    async abandonSession () {
        return await this.doPatchRequest(`/${this.ns}/state`, { state: 'abandoned' })
    }

    async updatePersonalIdUploads (uploadId) {
        return await this.doPatchRequest(`/${this.ns}/personal-id`, uploadId)
    }

    async deletePersonalIdUploads (uploadId) {
        return await this.doDeleteRequest(`/${this.ns}/personal-id/${uploadId}`)
    }

    async deleteExternalAcquirer () {
        return await this.doDeleteRequest(`/${this.ns}/external-acquirer`)
    }

    async validateData (data, name) {
        return await this.doPostRequest(`/${this.ns}/validate`, data, { name })
    }
}
