import { Bootstrap } from './Bootstrap.js'
// import Vue from 'vue'
// import i18n from './i18n'
const appElement = document.getElementById('app')

if (
    appElement instanceof Element
) {
    const appBootstrap = new Bootstrap()
    appBootstrap.initialize()
    appBootstrap.render(appElement)
}

// eslint-disable-next-line no-unused-vars
/*
const app = new Vue(
    {
        i18n,

        render: function (h) {
            return h()
        }
    }
)
*/
