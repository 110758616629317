import { axiosClient as axios } from './AxiosPlugin.js'
import Vue from 'vue'

class User {
    constructor (
        onLoggedInCallback,
        onLoggedOutCallback
    ) {
        this.loggedInCallbacks = []
        this.loggedOutCallbacks = []

        this.onLogin(onLoggedInCallback)
        this.onLogout(onLoggedOutCallback)

        this.vm = new Vue({
            data: {
                isLoggedIn: false,
                id: null,
                dn: null,
                controls: [],
                sn: null,
                givenName: null,
                sAMAccountName: null,
                mail: null
            },
            render () {
                return null
            }
        })
    }

    onLoggedIn (data) {
        this.vm.isLoggedIn = true
        this.vm.id = data.sAMAccountName
        this.vm.dn = data.dn
        this.vm.controls = data.controls
        this.vm.sn = data.sn
        this.vm.givenName = data.givenName
        this.vm.sAMAccountName = data.sAMAccountName
        this.vm.mail = data.mail
        for (const fn of this.loggedInCallbacks) {
            fn(this)
        }
    }

    onLoggedOut () {
        this.vm.isLoggedIn = false
        this.vm.id = null
        this.vm.dn = null
        this.vm.controls = []
        this.vm.sn = null
        this.vm.givenName = null
        this.vm.sAMAccountName = null
        this.vm.mail = null

        for (const fn of this.loggedOutCallbacks) {
            fn(this)
        }
    }

    get id () {
        return this.sAMAccountName
    }

    get dn () {
        if (this.vm.isLoggedIn) {
            return this.vm.dn
        }
        return null
    }

    get controls () {
        if (this.vm.isLoggedIn) {
            return this.vm.controls
        }
        return []
    }

    get sn () {
        if (this.vm.isLoggedIn) {
            return this.vm.sn
        }
        return null
    }

    get givenName () {
        if (this.vm.isLoggedIn) {
            return this.vm.givenName
        }
        return null
    }

    get sAMAccountName () {
        if (this.vm.isLoggedIn) {
            return this.vm.sAMAccountName
        }
        return null
    }

    get mail () {
        if (this.vm.isLoggedIn) {
            return this.vm.mail
        }
        return null
    }

    get isLoggedIn () {
        return this.vm.isLoggedIn
    }

    onLogin (callbackFn) {
        if (typeof callbackFn === 'function') {
            this.loggedInCallbacks.push(callbackFn)
        }
    }

    onLogout (callbackFn) {
        if (typeof callbackFn === 'function') {
            this.loggedOutCallbacks.push(callbackFn)
        }
    }

    async reload () {
        const response = await axios.get(`${process.env.VUE_APP_API_BASE}/api/auth/me`)
        if (response.status === 200) {
            this.onLoggedIn(response.data)
        } else {
            this.onLoggedOut()
        }
        return this
    }
}

export const UserPlugin = {
    install (Vue) {
        const user = new User()
        Vue.prototype.$user = user
    }
}
