import { ApiModuleBase } from '../ApiModuleBase.js'

export class DataCheckup extends ApiModuleBase {
    static get namespace () {
        return 'dataCheckup'
    }

    static get urlNamespace () {
        return 'data-checkup'
    }

    get uploaderUrl () {
        return `/${this.ns}/uploads`
    }

    sendUpdateData (data) {
        return this.doPostRequest(`/${this.ns}`, data)
    }

    listUploadedFiles () {
        return this.doGetRequest(`/${this.ns}/uploads`)
    }

    deleteUploadedFile (uploadId) {
        return this.doDeleteRequest(`/${this.ns}/uploads/${uploadId}`)
    }
}
