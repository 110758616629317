import { ApiModuleBase } from '../ApiModuleBase.js'

export class ClientSessionCompanyPeople extends ApiModuleBase {
    static get namespace () {
        return 'clientSessionCompanyPeople'
    }

    static get urlNamespace () {
        return 'client-session/company-people'
    }
}
