import axios from 'axios'
import { Api } from '../Services/Api/ApiClient/Api.js'

function clientFactory (baseUrl) {
    const client = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    })

    client.interceptors.response.use(
        function (response) {
            return response
        }, function (error) {
            if (error.response &&
                error.response.data &&
                error.response.status === 403 &&
                error.response.data.location
            ) {
                window.location = error.response.data.location
            }
            return Promise.reject(error)
        }
    )
    return client
}

export const ApiPlugin = (baseUrl, urlPrefix) => {
    const apiInstance = new Api(clientFactory(baseUrl), urlPrefix)

    return {
        install (Vue) {
            Vue.prototype.$api = apiInstance
        },
        getApiClient () {
            return apiInstance
        }
    }
}
