import { ApiModuleBase } from '../ApiModuleBase.js'

export class ClientSessionBankAccounts extends ApiModuleBase {
    static get namespace () {
        return 'clientSessionBankAccounts'
    }

    static get urlNamespace () {
        return 'client-session/bank-accounts'
    }
}
