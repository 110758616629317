import { ApiModuleBase } from '../ApiModuleBase.js'

export class ClientSessionRealOwners extends ApiModuleBase {
    static get namespace () {
        return 'clientSessionRealOwners'
    }

    static get urlNamespace () {
        return 'client-session/real-owners'
    }
}
