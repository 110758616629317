import modules from './modules'

export class Api {
    constructor (client, urlPrefix) {
        for (const ApiModule of modules) {
            this[ApiModule.namespace] = new ApiModule(client, ApiModule.urlNamespace, urlPrefix)
        }
        this.client = client
    }
}
