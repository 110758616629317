export default (adminGuard, loaderSettings) => {
    return [{
        path: '/admin',
        component: () => import(/* webpackChunkName: "admin" */ '../../Views/Admin/Index.vue'),
        beforeEnter: adminGuard.beforeEnter(),
        children: [
            {
                path: '',
                name: 'AdminIndex',
                component () {
                    return {
                        component: import(/* webpackChunkName: "adminIndex" */ '../../Views/Admin/Views/Index/IndexView.vue'),
                        ...loaderSettings
                    }
                }
            },
            {
                path: 'users',
                name: 'AdminUsers',
                component () {
                    return {
                        component: import(/* webpackChunkName: "users" */ '../../Views/Admin/Views/Users/UsersView.vue'),
                        ...loaderSettings
                    }
                }
            },
            {
                path: 'external-acquirers',
                name: 'AdminExternalAcquirers',
                component () {
                    return {
                        component: import(/* webpackChunkName: "externalAcquirers" */ '../../Views/Admin/Views/ExternalAcquirers/ExternalAcquirersView.vue'),
                        ...loaderSettings
                    }
                }
            },
            {
                path: 'client-sessions',
                component () {
                    return {
                        component: import(/* webpackChunkName: "adminClientSessionsIndex" */ '../../Views/Admin/Views/ClientSessions/Index.vue'),
                        ...loaderSettings
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'AdminClientSessions',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "adminClientSessions" */ '../../Views/Admin/Views/ClientSessions/Views/ClientSessionsList/ClientSessionsList.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: ':sessionId',
                        name: 'AdminClientSessionDetail',
                        props: true,
                        component () {
                            return {
                                component: import(/* webpackChunkName: "adminClientSessionDetail" */ '../../Views/Admin/Views/ClientSessions/Views/ClientSessionDetail/ClientSessionDetail.vue'),
                                ...loaderSettings
                            }
                        }
                    }
                ]
            },
            {
                path: 'aml-sessions',
                component () {
                    return {
                        component: import(/* webpackChunkName: "adminAmlSessionsIndex" */ '../../Views/Admin/Views/AmlSessions/Index.vue'),
                        ...loaderSettings
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'AdminAmlSessions',
                        component () {
                            return {
                                component: import(/* webpackChunkName: "adminAmlSessions" */ '../../Views/Admin/Views/AmlSessions/Views/AmlSessionsList/AmlSessionsList.vue'),
                                ...loaderSettings
                            }
                        }
                    },
                    {
                        path: ':amlSessionId',
                        name: 'AdminAmlSessionDetail',
                        props: true,
                        component () {
                            return {
                                component: import(/* webpackChunkName: "adminAmlSessionDetail" */ '../../Views/Admin/Views/AmlSessions/Views/AmlSessionDetail/AmlSessionDetail.vue'),
                                ...loaderSettings
                            }
                        }
                    }
                ]
            }
        ],
        ...loaderSettings
    }]
}
