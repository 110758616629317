import { ApiModuleBase } from '../ApiModuleBase.js'

export class Enums extends ApiModuleBase {
    static get namespace () {
        return 'enums'
    }

    static get urlNamespace () {
        return 'enums'
    }

    async getRiskActivities () {
        return this.doGetRequest(`${this.ns}/risk-activities`)
    }

    async getCountries () {
        return this.doGetRequest(`${this.ns}/countries`)
    }
}
