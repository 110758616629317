import {
    BIcon, BIconX, BIconChevronDoubleLeft, BIconChevronDoubleRight, BIconCode,
    BIconArrowsMove, BIconTrash, BIconPencilSquare, BIconPlusCircle, BIconPlus,
    BIconThreeDots, BIconWallet, BIconExclamationCircle, BIconCheckCircle,
    BIconSearch, BIconTools, BIconGear, BIconEye, BIconEyeSlash, BIconFiles,
    BIconFolder, BIconDash, BIconLock, BIconArrowRepeat, BIconFilter, BIconList,
    BIconArrowBarLeft, BIconArrowBarRight, BIconLockFill, BIconCheck, BIconPersonCircle,
    BIconArrowUp, BIconArrowDown, BIconArrowsExpand, BIconPersonPlusFill, BIconPersonCheckFill,
    BIconPerson, BIconBriefcase, BIconCloudUploadFill, BIconInfoCircle, BIconFileSpreadsheet,
    BIconFileText, BIconFilePost, BIconFileEarmarkPlus, BIconFileEarmarkMinus, BIconCloudDownload,
    BIconArrowLeft, BIconArrowLeftSquare, BIconClipboard, BIconClipboardCheck
} from 'bootstrap-vue'

const icons = [
    BIcon, BIconX, BIconChevronDoubleLeft, BIconChevronDoubleRight, BIconCode,
    BIconArrowsMove, BIconTrash, BIconPencilSquare, BIconPlusCircle, BIconPlus,
    BIconThreeDots, BIconWallet, BIconExclamationCircle, BIconCheckCircle,
    BIconSearch, BIconTools, BIconGear, BIconEye, BIconEyeSlash, BIconFiles,
    BIconFolder, BIconDash, BIconLock, BIconArrowRepeat, BIconFilter, BIconList,
    BIconArrowBarLeft, BIconArrowBarRight, BIconLockFill, BIconCheck, BIconPersonCircle,
    BIconArrowUp, BIconArrowDown, BIconArrowsExpand, BIconPersonPlusFill, BIconPersonCheckFill,
    BIconPerson, BIconBriefcase, BIconCloudUploadFill, BIconInfoCircle, BIconFileSpreadsheet,
    BIconFileText, BIconFilePost, BIconFileEarmarkPlus, BIconFileEarmarkMinus, BIconCloudDownload,
    BIconArrowLeft, BIconArrowLeftSquare, BIconClipboard, BIconClipboardCheck
]

export const IconsLibraryPlugin = {
    install (Vue) {
        for (const component of icons) {
            Vue.component(component.options.name, component)
        }
    }
}
