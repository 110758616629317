import { ApiModuleBase } from '../ApiModuleBase.js'

export class AmlSessions extends ApiModuleBase {
    static get namespace () {
        return 'amlSessions'
    }

    static get urlNamespace () {
        return 'aml-sessions'
    }

    async exportToIvan (sessionId) {
        return await this.doGetRequest(`${this.ns}/${sessionId}/export`)
    }

    async changeState (sessionId, state) {
        return this.doPatchRequest(`${this.ns}/${sessionId}/state`, state)
    }
}
