<template>
    <div class="alert alert-danger text-center">
        Omlouváme se, ale došlo k chybě při načtení komponenty, zkuste prosím znovu načíst stránku.
    </div>
</template>

<script>
export default {
    name: 'ComponentError'
}
</script>
