export default {
    state: () => ({
        basicDataOld: null,
        personalIdOld: null,
        bankAccountOld: null,
        companyPersonOld: null,
        legalInfoOld: null,
        realOwnerOld: null,
        basicData: null,
        personalId: null,
        bankAccount: null,
        companyPerson: null,
        legalInfo: null,
        realOwner: null
    }),
    getters: {
    },
    mutations: {
        setDataHash (state, data) {
            if (data.name && data.hash !== undefined && state[data.name] !== undefined) {
                state[data.name] = data.hash
            }
        }
    },
    actions: {

    }
}
