
class Notifier {
    constructor (VuePrototype) {
        this.VuePrototype = VuePrototype
        this.vm = null
        this.settings = {
            autoHideDelay: 6 * 1000,
            appendToast: true
        }
    }

    _message (message, type, title) {
        if (this.vm === null) {
            this.vm = new this.VuePrototype()
        }
        this.vm.$bvToast.toast(message, {
            title: `${title}`,
            variant: type,
            ...this.settings
        })
    }

    error (message) {
        this._message(message, 'danger', 'Chyba')
    }

    warning (message) {
        this._message(message, 'warning', 'Varování')
    }

    info (message) {
        this._message(message, 'info', 'Informace')
    }

    success (message) {
        this._message(message, 'success', 'Úspěch')
    }
}

export const NotifierPlugin = {
    install (Vue) {
        Vue.prototype.$notify = new Notifier(Vue)
    }
}
