import { ApiModuleBase } from '../ApiModuleBase.js'

export class ClientSessionSignatures extends ApiModuleBase {
    static get namespace () {
        return 'clientSessionSignatures'
    }

    static get urlNamespace () {
        return 'client-session/signatures'
    }

    async checkSignature () {
        return await this.doHeadRequest(`/${this.ns}`)
    }
}
