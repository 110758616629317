import { ApiModuleBase } from '../ApiModuleBase.js'

export class ClientSessionUploads extends ApiModuleBase {
    static get namespace () {
        return 'clientSessionUploads'
    }

    static get urlNamespace () {
        return 'client-session/uploads'
    }

    get uploaderUrl () {
        return `${this.client.defaults.baseURL}/${this.ns}`
    }
}
