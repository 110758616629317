import { ApiModuleBase } from '../ApiModuleBase.js'

export class Auth extends ApiModuleBase {
    static get namespace () {
        return 'auth'
    }

    static get urlNamespace () {
        return 'auth'
    }

    login (username, password) {
        return this.doPostRequest(`/${this.ns}/ldap`, { username, password })
    }

    logout () {
        return this.doGetRequest(`/${this.ns}/logout`)
    }
}
