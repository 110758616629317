import { ApiModuleBase } from '../ApiModuleBase.js'

export class AmlSessionSignatures extends ApiModuleBase {
    static get namespace () {
        return 'amlSessionSignatures'
    }

    static get urlNamespace () {
        return 'aml-session/signatures'
    }

    async checkSignature () {
        return await this.doHeadRequest(`/${this.ns}`)
    }
}
