import { axiosClient } from '../../Plugins/AxiosPlugin.js'

export class HeadRequestGuard {
    constructor (requestUrl, redirectRoute = null) {
        this.requestUrl = requestUrl
        this.redirectRoute = redirectRoute
    }

    beforeEnter (customParams = null, customRedirect = null) {
        const requestUrl = this.requestUrl
        return (to, from, next) => {
            axiosClient.head(requestUrl, { params: customParams || {} })
                .then((response) => {
                    if (response.status === 200 || response.status === 204) {
                        next()
                    }
                }).catch(() => {
                    let redirectRoute = null
                    if ((typeof customRedirect === 'object') && customRedirect !== null) {
                        redirectRoute = customRedirect
                    } else if ((typeof this.redirectRoute === 'object') && this.redirectRoute !== null) {
                        redirectRoute = this.redirectRoute
                    }
                    if (redirectRoute) {
                        if (this.redirectRoute.url) {
                            window.location = this.redirectRoute.url
                            return
                        }
                        try {
                            next(this.redirectRoute)
                        } catch (e) {
                            // nothing
                        }
                    } else {
                        next(false)
                    }
                })
        }
    }
}
