import { Auth } from './Auth'
import { ClientSession } from './ClientSession'
import { ExternalAcquirers } from './ExternalAcquirers'
import { Enums } from './Enums'
import { Users } from './Users'
import { ClientSessionUploads } from './ClientSessionUploads'
import { ClientSessionBankAccounts } from './ClientSessionBankAccounts'
import { ClientSessionCompanyPeople } from './ClientSessionCompanyPeople'
import { ClientSessionRealOwners } from './ClientSessionRealOwners'
import { Locale } from './Locale'
import { ClientSessionSignatures } from './ClientSessionSignatures'
import { Sessions } from './Sessions'
import { ExportStates } from './ExportStates'
import { AmlSession } from './AmlSession'
import { AmlSessionRealOwners } from './AmlSessionRealOwners'
import { AmlSessionSignatures } from './AmlSessionSignatures'
import { DataCheckup } from './DataCheckup'
import { AmlSessions } from './AmlSessions'
import { AmlSessionUploads } from './AmlSessionUploads'

export default [
    Auth,
    Users,
    ExternalAcquirers,
    Enums,
    ClientSession,
    ClientSessionUploads,
    ClientSessionBankAccounts,
    ClientSessionCompanyPeople,
    ClientSessionRealOwners,
    Locale,
    ClientSessionSignatures,
    Sessions,
    ExportStates,
    AmlSession,
    AmlSessionRealOwners,
    AmlSessionSignatures,
    DataCheckup,
    AmlSessions,
    AmlSessionUploads
]
