<template>
    <p class="mb-2">
        <strong>{{title}}</strong><br/>
        <slot>
            <template v-if="enableCompare">
                <span :class="{'d-block': true, 'text-danger text-line-through font-weight-bold': isSame === false }">{{contentText}}</span>
                <span v-if="isSame === false" class="d-block font-weight-bold text-success">{{`${compareContent}`}}</span>
            </template>
            <span v-else>{{contentText}}</span>
        </slot>
    </p>
</template>

<script>
export default {
    name: 'DetailItem',
    props: {
        title: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: false
        },
        enableCompare: {
            type: Boolean,
            required: false,
            default: false
        },
        compareContent: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        contentText () {
            return (this.content !== null) ? `${this.content}` : 'Neuvedeno'
        },
        isSame () {
            if (this.enableCompare) {
                return `${this.content}`.trim() === `${this.compareContent}`.trim()
            }
            return null
        }
    }
}
</script>

<style>
.text-line-through {
    text-decoration: line-through !important;
}
</style>
