<template>
    <div v-if="$user.isLoggedIn" class="d-flex">
        <b-nav-item
            :to="{ name: 'AdminAmlSessions' }"
        >
            <span class="iconify mr-2 text-primary" data-icon="mdi:file-sign"></span> {{$t('navigation.admin.amlSessions')}}
        </b-nav-item>
        <b-nav-item
            :to="{ name: 'AdminClientSessions' }"
        >
            <span class="iconify mr-2 text-primary" data-icon="mdi:file-document-edit"></span> {{$t('navigation.admin.clientSession')}}
        </b-nav-item>
        <b-nav-item
            :to="{ name: 'AdminExternalAcquirers' }"
        >
            <span class="iconify mr-2 text-primary" data-icon="mdi:link"></span> {{$t('navigation.admin.externalAcquirers')}}
        </b-nav-item>
        <b-nav-item-dropdown>
            <template #text>
                <span class="iconify mr-2 text-primary" data-icon="mdi:cog"></span> {{$t('navigation.admin.admin')}}
            </template>
            <b-dropdown-item
                :to="{ name: 'AdminUsers' }"
            >
                <span class="iconify mr-2 text-primary" data-icon="mdi:people"></span> {{$t('navigation.admin.users')}}
            </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown>
            <template #button-content>
                <span class="iconify mr-2 text-primary" data-icon="mdi:account"></span>{{username}}
            </template>
            <b-dropdown-item  @click.prevent="onLogout">
                <span class="iconify mr-2 text-danger" data-icon="mdi:account-lock"></span> {{$t('navigation.logout')}}
            </b-dropdown-item>
        </b-nav-item-dropdown>
    </div>
</template>

<script>
export default {
    name: 'AppNavigation',
    computed: {
        username () {
            return (this.$user.isLoggedIn) ? `${this.$user.givenName} ${this.$user.sn}` : this.$t('navigation.notLoggedIn')
        }
    },
    methods: {
        async onLogout () {
            if (this.$user.isLoggedIn) {
                await this.$api.auth.logout()
            }
            window.location = '/app'
        }
    }
}
</script>
